import * as bootstrap from 'bootstrap';
import Swiper from 'swiper/bundle';
import Swal from 'sweetalert2';
import Noty from 'noty';

import 'swiper/css/bundle';
import 'fslightbox';

import.meta.glob([
    '../img/**'
]);

class App {
    constructor() {
        this.init();
    }

    init() {
        this.tables();

        this.menu();

        this.search();

        this.cookies();

        this.initSwiper();

        this.initDropdown();

        this.asyncRequests();

        this.asyncForms();
    }

    showNotification(text, status) {
        new Noty({
            timeout: 3000,
            text: text,
            type: status,
            layout: 'topRight',
            theme: 'bootstrap-v4'
        }).show();
    }

    tables() {
        document.querySelectorAll('tr[data-url]').forEach(tableRow => {
            tableRow.addEventListener('click', function() {
                window.location.href = this.dataset.url;
            });
        });
    }

    menu() {
        let closeSidebar = () => {
            document.querySelectorAll('.sidebar').forEach(sidebar => {
                // Remove open class
                sidebar.classList.remove('open');
            });

            document.querySelectorAll('.open-sidebar').forEach(buttonToggle => {
                // Remove open class
                buttonToggle.classList.remove('open');
            });

            // Remove sidebar-open class
            document.body.classList.remove('sidebar-open');
        }

        // Bind click event listener to the toggle sidebar button
        document.querySelectorAll('.open-sidebar').forEach(element => {
            // Add click event listener to the element
            element.addEventListener('click', function () {
                let target = document.getElementById(element.getAttribute('data-target'));

                if (target) {
                    target.classList.toggle('open');
                }

                // Toggle open class
                this.classList.toggle('open');

                // Toggle sidebar-open class
                document.body.classList.toggle('sidebar-open');
            });
        });

        document.querySelectorAll('.sidebar .close').forEach(element => {
            // Bind click event listener to the sidebar close button
            element.addEventListener('click', function () {
                // Close the sidebar
                closeSidebar();
            });
        });

        // Bind the click event listener to close the sidebar if the user clicks outside the sidebar
        document.addEventListener('click', function (event) {
            // Check if the user clicked outside the sidebar and outside the toggle button
            if (!event.target.closest('.sidebar') && !event.target.closest('.open-sidebar')) {
                // Check if the sidebar is open before actually closing it
                if (document.body.classList.contains('sidebar-open')) {
                    // Close the sidebar
                    closeSidebar();
                }
            }
        });

        // Bind the escape keydown event listener to close the sidebar if open
        document.addEventListener('keydown', function (event) {
            // Check for the escape key
            if (event.key === 'Escape') {
                // Check if the sidebar is open before actually closing it
                if (document.body.classList.contains('sidebar-open')) {
                    // Close the sidebar
                    closeSidebar();
                }
            }
        });
    }

    search() {
        document.querySelectorAll('[data-action="toggle-search"]').forEach(button => {
            // Bind the click event listener to the search toggle buttons
            button.addEventListener('click', function() {
                // Toggle search-open class
                document.body.classList.toggle('search-open');
            });
        });
    }

    /**
     * Set cookie preferences
     */
    cookies() {
        let form = document.getElementById('form-cookies');

        if (form === null) {
            return false;
        }

        // Show the cookie form
        form.classList.remove('fade');

        // Add cookie selection state
        document.body.classList.add('cookie-selection');

        let options = form.querySelector('.cookie-options');

        let textChecked   = 'Alle cookies accepteren';
        let textUnchecked = 'Geselecteerde cookies accepteren'

        let buttonSubmit = form.querySelector('button[type="submit"]');

        let isAllCookiesChecked = function () {
            let checked = true;

            options.querySelectorAll('.form-check-input').forEach(switchElement => {
                if (switchElement.checked === false) {
                    checked = false;
                }
            });

            return checked;
        }

        options.querySelectorAll('.form-check-input').forEach(input => {
            // Bind the change event listener to the form check input
            input.addEventListener('change', function () {
                buttonSubmit.innerText = isAllCookiesChecked() ? textChecked : textUnchecked;
            });

            // Manually create on on-change event
            let event = new Event('change');

            // Manually dispatch event
            input.dispatchEvent(event);
        });

        form.addEventListener('submit', function(e) {
            e.preventDefault();

            $.ajax({
                url: this.action,
                method: 'POST',
                dataType: 'JSON',
                processData: false,
                contentType: false,
                data: new FormData(this)
            })
            .done(function() {
                // Remove cookie selection state
                document.body.classList.remove('cookie-selection');

                // Fade out the form
                form.classList.add('fade');

                setTimeout(function () {
                    // Reload page
                    location.reload();
                }, 1000);
            });
        });
    }

    initSwiper() {
        document.querySelectorAll('.testimonial-carousel').forEach(element => {
            new Swiper('#' + element.getAttribute('id'), {
                pagination: {
                    el: element.parentElement.querySelector('.swiper-pagination'),
                    clickable: true,
                    type: 'bullets'
                },
                breakpoints: {
                    0: {
                        slidesPerView: 1
                    },
                    768: {
                        slidesPerView: 2
                    },
                    992: {
                        slidesPerView: 2
                    },
                    1200: {
                        slidesPerView: 3
                    },
                    1600: {
                        slidesPerView: 3
                    }
                },
                slidesPerGroup: 1,
                spaceBetween: 25,
                loop: true
            });
        });

        document.querySelectorAll('.photo-carousel').forEach(element => {
            new Swiper('#' + element.getAttribute('id'), {
                pagination: {
                    el: element.parentElement.querySelector('.swiper-pagination'),
                    clickable: true,
                    type: 'bullets'
                },
                navigation: {
                    prevEl: element.parentElement.querySelector('.nav-arrow-prev'),
                    nextEl: element.parentElement.querySelector('.nav-arrow-next')
                },
                slidesPerView: 1,
                slidesPerGroup: 1,
                spaceBetween: 25,
                loop: true
            });
        });

        document.querySelectorAll('.product-carousel').forEach(element => {
            let thumbsSwiper = new Swiper('#thumbs-' + element.getAttribute('id'), {
                spaceBetween: 25,
                slidesPerView: 4,
                loop: false,
                freeMode: true,
                watchSlidesProgress: true,
                breakpoints: {
                    0: {
                        direction: 'horizontal'
                    },
                    992: {
                        direction: 'vertical'
                    }
                }
            });

            new Swiper('#' + element.getAttribute('id'), {
                spaceBetween: 25,
                loop: false,
                thumbs: {
                    swiper: thumbsSwiper
                },
                navigation: {
                    prevEl: element.parentElement.querySelector('.nav-arrow-prev'),
                    nextEl: element.parentElement.querySelector('.nav-arrow-next')
                }
            });
        });
    }

    initDropdown() {
        let dropdown = document.getElementById('select-category-urls');

        if (dropdown === null) {
            return;
        }

        dropdown.addEventListener('change', function () {
            const selectedValue = this.value;

            if (selectedValue) {
                // Redirect to the selected URL
                window.location.href = selectedValue;
            }
        });
    }

    asyncRequests() {
        let app = this; // Reference to the current instance

        $(document).on('click', '.async-request', function() {
            Swal.fire({
                title              : this.dataset.title,
                text               : this.dataset.message,
                icon               : 'info',
                showCancelButton   : true,
                confirmButtonColor : '#328ce8',
                cancelButtonColor  : '#328ce8',
                confirmButtonText  : 'Doorgaan',
                cancelButtonText   : 'Annuleren'
            }).then((result) => {
                if (result.value) {
                    $.ajax({
                        method: $(this).data('method'),
                        url: $(this).data('url'),
                        dataType: 'JSON',
                        processData: false,
                        contentType: false
                    })
                    .done(function(response) {
                        if (response.redirect_url) {
                            window.location.replace(response.redirect_url);
                        }

                        if (response.message) {
                            app.showNotification(response.message, 'success');
                        }
                    })
                    .fail(function(error) {
                        Swal.fire('Foutmelding', error.statusText, 'error');
                    });
                }
            });
        });
    }

    asyncForms() {
        document.querySelectorAll('.form-async').forEach(form => {
            form.addEventListener('submit', function(e) {
                e.preventDefault();

                // Fetch container for the form messages
                let formMessages = this.querySelector('.form-messages');

                // Clear the form errors
                this.querySelectorAll('[data-error]').forEach(errorMessage => {
                    // Clear error message
                    errorMessage.innerHTML = '';

                    // Remove multiple status class
                    errorMessage.classList.remove('multiple');
                });

                // Clear the form messages
                formMessages.replaceChildren();

                $.ajax({
                    method: this.getAttribute('method'),
                    url: this.getAttribute('action'),
                    dataType: 'JSON',
                    processData: false,
                    contentType: false,
                    data: new FormData(this)
                }).done(function(response) {
                    // Reset form
                    form.reset();

                    // Reset reCAPTCHA
                    grecaptcha.reset();

                    formMessages.innerHTML = '<div class="alert alert-' + response.message.type + '"><span>' + response.message.text + '</span></div>';
                }).fail(function(error) {
                    // Reset reCAPTCHA
                    grecaptcha.reset();

                    switch (error.status) {
                        case 422:
                            // Set error messages
                            Object.entries(error.responseJSON.errors).forEach(([key, errors]) => {
                                // Find the target element with data-error attribute
                                const targetElement = form.querySelector('[data-error="' + key + '"]');

                                if (targetElement === null) {
                                    return;
                                }

                                if (errors.length > 1) {
                                    // Mark container as multiple errors
                                    targetElement.classList.add('multiple');
                                }

                                errors.forEach(function (error) {
                                    // Create a new <span> element
                                    const errorSpan = document.createElement('span');

                                    // Set the text content of the <span>
                                    errorSpan.textContent = error;

                                    // Append the <span> as a child of the target element
                                    targetElement.appendChild(errorSpan);
                                });
                            });
                            break;

                        default:
                            // Show notification
                            alert('Er is iets misgegaan.');
                    }
                });
            });
        });
    }
}

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

window.addEventListener('load', () => new App());
